<template>
  <section>
    <div class="content">
      <a id="content" class="anchor"></a>
      <div class="inner">
        <div class="split">
          <div class="split-left" v-in-viewport.once>
            <h3>About Us</h3>
            <p><strong
                >
              Hello! We are Joe &amp; Emily Waite. With nearly two decades of arboricultural experience under our belt, we specialise in surveying and assessing trees then providing high-quality reports.</strong
                ></p>

<p>We cover Grimsby, Immingham, Scunthorpe, Brigg, Louth, Barton On Humber, Hull and many more areas of Lincolnshire.</p>

<p>No matter the scale of your needs, our dedicated team is here to help with whatever your tree management goals are. We deliver accurate and insightful reports that meet your specific requirements. After collecting data on an initial site visit, we compile and analyse the information then produce an easy to read report with clearly defined advice.</p>

<p>Waites Tree Surveys are set apart by our commitment to delivering exceptional service and strong client relationships. As a small-scale business, we prioritise effective communication and personalised attention.</p>

<p>Importantly we carry certification from The Arb Association, National Diploma in Forestry and Arboriculture, and hold a Professional Tree Inspection certificate from LANTRA. We pride ourselves on staying current with industry standards and best practices to provide you with accurate, up to date consultancy.</p>

<p>To get started, simply reach out to discuss your needs. </p>

<p>Fill in our convenient online request form or give us a call, and our friendly team will be happy to assist you.</p>

<p>Trust Waites Tree Surveys for all your Arboricultural Tree Report needs in the Grimsby &amp; Lincolnshire area.</p>
            <img class="spaced" src="/images/logos.jpg" alt="Tree Reports Grimsby" />
          </div>
          <div class="split-right" v-in-viewport.once>
            <img src="/images/about-tree-reports.jpg" alt="Tree Reports Lincolnshire" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "about",
  data() {
    return {}
  }
}
</script>

<style scoped>
.spaced {
  margin-top: 4rem;
}
</style>
