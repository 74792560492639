<template>
  <section>
    <div class="content">
      <a id="content" class="anchor"></a>
      <div class="inner">
        <div class="no-split" v-in-viewport.once>
          <h3>Our Services</h3>
          <p>
            <strong
              >We take pride in creating reports that are clear, easy to read, and tailored to our clients'
              requirements. Our goal is to provide you with a document that not only meets industry standards but also
              serves as a valuable resource for your records. With our professionally presented results, you'll have a
              comprehensive understanding of the condition, safety, and management requirements of your trees.</strong
            >
          </p>
          <table class="pricingtable">
            <tr><td><ul class="pricing">
  <li>Tree Reports:
    <ul>
      <li><a href @click.prevent="scrollFix('#s1')">Individual Tree Safety Inspection Survey & Report</a></li>
      <li><a href @click.prevent="scrollFix('#s2')">Multiple Trees Safety Inspection Survey & Report</a></li>
      <li><a href @click.prevent="scrollFix('#s3')">Emergency Tree Safety Report</a></li>
      <li><a href @click.prevent="scrollFix('#s4')">Arboricultural Impact Assessment Report</a></li>
      <li><a href @click.prevent="scrollFix('#s5')">Tree Mortgage Report</a></li>
      <li><a href @click.prevent="scrollFix('#s6')">Tree Health Check-up Report</a></li>
      <li><a href @click.prevent="scrollFix('#s7')">Tree Preservation Order (TPO) Application Report</a></li>
      <li><a href @click.prevent="scrollFix('#s8')">Expert Witness Tree Safety Report</a></li>
    </ul>
  </li>
</ul></td><td><ul class="pricing">

  <li>Other Services:
    <ul>
      <li><a href @click.prevent="scrollFix('#s9')">Tree Consultancy</a></li>
      <li><a href @click.prevent="scrollFix('#s10')">Aerial Inspection</a></li>
      <li><a href @click.prevent="scrollFix('#s11')">Roadside Tree Safety Survey</a></li>
      <li><a href @click.prevent="scrollFix('#s12')">Arboricultural Clerk of Works</a></li>
    </ul>
  </li>
</ul></td></tr>
          </table>



          <h4>Tree Reports</h4>
          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s1"></a>Individual Tree Safety Inspection Survey &amp; Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> A basic visual assessment of the tree's health, risk
                analysis, and providing recommendations.
              </td>
            </tr>

          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s2"></a>Multiple Trees Safety Inspection Survey & Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> A package deal for a basic visual assessment of
                health, risk analysis, and providing recommendations reporting on multiple trees on the same property.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s3"></a>Emergency Tree Safety Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> An expedited report requested in emergency situations.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s4"></a>Arboricultural Impact Assessment Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> A report assessing the impact of a proposed
                development on trees in the vicinity.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s5"></a>Tree Mortgage Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> A report requested by mortgage lenders or home
                sellers/buyers to assess the risks posed by trees on the property.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s6"></a>Tree Health Check-up Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> Periodic health check-ups for trees under ongoing
                monitoring.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s7"></a>Tree Preservation Order (TPO) Application Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> A report required when applying for permission to work
                on trees under TPO.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s8"></a>Expert Witness Tree Safety Report</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> A specialised report prepared by an expert witness for
                legal proceedings.
              </td>
            </tr>
          </table>
          <h4>Other services</h4>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s9"></a>Tree Consultancy </td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> Providing expert advice and guidance.
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s10"></a>Aerial Inspection </td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> An inspection of the tree performed at height with either rope access or a mechanised platform. 
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s11"></a>Roadside Tree Safety Survey</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> An assessment of all roadside trees (often for land owners) in compliance with Highway Law. 
              </td>
            </tr>
          </table>

          <table class="table">
            <tr>
              <td class="table-heading"><a class="anchorsmall" id="s12"></a>Arboricultural Clerk of Works</td>
              <td class="table-desc">
                <span class="material-icons-outlined">feed</span> Undertaking this role to ensure compliance on building and construction sites.  
              </td>
            </tr>
          </table>
          <p><em>All prices will be clarified at the point of Quotation.</em></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "services",
  data() {
    return {}
  },
  methods: {
    scrollFix: function(hash) {
      setTimeout(() => document.getElementById(hash.replace("#", "")).scrollIntoView({ behavior: "smooth" }), 1)
    }
  },
}
</script>

<style scoped>
.table {
  margin-top: 4rem;
  width: 100%;
  border-collapse: collapse;
}
.table td {
  border: 1px solid #f5f5f5;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  background: #fff;
}
td.table-heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #c53931;
  font-family: "Koh Santepheap", cursive;
  width: 25%;
  background: #f5f5f5;
}
tr:nth-child(even) td.table-heading {
  color: rgba(0, 102, 102, 1);
}
td.table-heading,
td.table-price {
}

.material-icons-outlined {
  background: #c53931;
  color: white;
  border-radius: 100%;
  padding: 0.35rem;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin-right: 1rem;
}

.pricingtable td {
  vertical-align: top;
  padding-right: 8rem;
}

.pricingtable {
  padding-bottom: 2rem;
}

.pricing > li {  
  font-weight: bold;
}

.pricing ul {
  margin:0;
  padding:1rem 0;
}

.pricing ul a {
  font-weight: normal;
  color: #c53931;
  text-decoration: underline;
}

@media screen and (max-width: 650px) {
  td {
    width: 100% !important;
    display: block;
  }
  td.table-heading {
    background: #f5f5f5;
    border-bottom: 0;
  }
}
</style>
