<template>
  <section>
    <div class="content">
      <a id="content" class="anchor"></a>
      <div class="inner">
        <div class="split">
          <div class="split-left" v-in-viewport.once>
            <h3>Tree surveys and reports</h3>
            <p>
              <strong
                >Do you require an Arboricultural Tree Report? Look no further!</strong
              >
            </p>
            <p>
              Our expert team at Waites Trees & Gardens offers top-notch arboricultural consultancy, backed by years of industry experience. As a well trusted business in the UK for Arboricultural Tree Reports, we pride ourselves on delivering accurate and detailed assessments of trees and their surrounding environments.
            </p>
            <p>
             Whether you require Tree Condition Reports, Hazard Assessments, or planning support, our dedicated professionals are here to assist you with tailored solutions that meet your specific requirements.
            </p>
            <p>
              You can trust Waites Trees & Gardens for reliable and timely reports, honest and down to earth communication and expert advice to support your decision-making processes. 
            </p>
            <p>We offer the following services: </p>
            <div class="service-grid">
              <div>
                <ul>
                  <li>
                    <span class="material-icons-outlined">task_alt</span> Tree Safety Inspection Survey &amp; Reports
                  </li>
                  <li><span class="material-icons-outlined">task_alt</span> Emergency Tree Safety Reports</li>
                  <li>
                    <span class="material-icons-outlined">task_alt</span> Arboricultural Impact Assessment Reports
                  </li>
                  <li><span class="material-icons-outlined">task_alt</span> Tree Mortgage Reports</li>
                </ul>
              </div>
              <div>
                <ul>
                  <li><span class="material-icons-outlined">task_alt</span> Tree Health Check-up Reports</li>
                  <li>
                    <span class="material-icons-outlined">task_alt</span> Tree Preservation Order (TPO) Application
                    Reports
                  </li>
                  <li><span class="material-icons-outlined">task_alt</span> Expert Witness Tree Safety Reports</li>
                  <li><span class="material-icons-outlined">task_alt</span> BS 5837 Tree Reports</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="split-right" v-in-viewport.once>
            <img src="/images/joe-waite-tree-surgeon.jpg" alt="Tree Surveys Grimsby by Waites" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "home",
  data() {
    return {}
  }
}
</script>

<style scoped>
.service-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 4rem;
  gap: 4rem;
  margin-top: 2rem;
  font-size: 1.5rem;
}

.service-grid li {
  margin: 1rem 0;
}

.services {
  background: #d9e8e8;
}

@media screen and (max-width: 950px) {
  .service-grid {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .service-grid ul {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
