<template>
  <section>
    <div class="content">
      <a id="content" class="anchor"></a>
      <div class="inner">
        <div class="split">
          <div class="split-left" v-in-viewport.once>
            <h3>Contact Us</h3>
            <p>
              <strong
                >To get started, simply reach out to us to discuss your needs. Fill in our convenient online request
                form or give us a call, and our friendly team will be happy to assist you.</strong
              >
            </p>
            <div class="contact">
              <div class="contact-telephone">
                <span class="material-icons-outlined">phone</span><a href="tel:07779991578">0777 999 1578</a>
              </div>
              <div class="contact-email">
                <span class="material-icons-outlined">email</span>
                <a href="mailto:info@waitestreesurveys.co.uk">info@waitestreesurveys.co.uk</a>
              </div>
              <div class="contact-address">
                <span class="material-icons-outlined">room</span>Ladysmith Road, Grimsby
              </div>
            </div>

            <div class="quote">
              <div class="quote-heading">
                Get a quick quote
              </div>
              <p class="quote-para">Fill in our simple form to get a fast, free, no-obligation quote.</p>
              <form class="quote-form" data-netlify="true" data-netlify-honeypot="bot-field" method="post" name="contact-form">
                <input name="form-name" required type="hidden" value="contact-form" />
                <input name="Name" required type="text" placeholder="Name *" />
                <input name="Number" required type="text" placeholder="Contact Number *" />
                <input name="Address" type="text" placeholder="Address" />
                <textarea name="Message" required placeholder="Message *"></textarea>
                <button type="submit"><img class="ripple" src="/images/ripple.svg" alt="Get a tree survey quote" /> Submit</button>
              </form>
            </div>
          </div>
          <div class="split-right" v-in-viewport.once>
            <img src="/images/tree-survey-contact.jpg" alt="Tree Surveys" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "about",
  data() {
    return {}
  }
}
</script>

<style scoped>
.quote {
  color: white;
  margin-top: 5rem;
  background: #006666;
  border-radius: 0.5rem;
}
.quote-heading {
  color:white
}
.contact {
  margin-top: 4rem;
}

.contact > div {
  margin-bottom: 1rem;
}
.material-icons-outlined {
  color: #c53931;
  position: relative;
  bottom: -0.6rem;
  margin-right: 1rem;
}
.contact-telephone {
  font-size: 2.4rem;
}
.contact-email {
  font-size: 2rem;
}
</style>
